import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_GARANTIAS);

class Otros {
	obtener_catalogos(options) {
		return http.get(utils.url_options('/catalogos',options));
	}

	obtener_catalogo(valor, tipo) {
		return !tipo ? http.get(`/catalogos/${valor}`) : http.get(`/catalogos/${valor}/${tipo}`);
	}

	crear_catalogo(payload) {
		return http.post(`/catalogos`, payload);
	}

	editar_catalogo(id, payload) {
		return http.put(`/catalogos/${id}`, payload);
	}

	eliminar_catalogos(id) {
		return http.delete(`/catalogos/${id}`);
	}

	obtener_mensajes(id, options) {
		return http.get(utils.url_options(`/portal_admin/garantia/${id}/mensaje`, options));
	}

	obtener_mensajes_all(options) {
		return http.get(`/portal_admin/mensajes`);
	}

	obtener_mensaje(id) {
		return http.get(`/portal_admin/mensajes/${id}`);	
	}

	leer_mensaje(id) {
		return http.get(`/portal_admin/mensajes/${id}/leer`);
	}

	cargar_layout(payload) {
		return http.post(`/layout`,payload);
	}
}

export default new Otros();