<template>
	<div>
		<div class="row mt-4">
			<h1 class="col-sm-12 text-right">Mensajes</h1>
		</div>

		<DataGrid :config="dataGridConfig" :data="mensajes" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div class="col-sm-2"><button class="btn warning-btn" @click="obtener_mensajes">Recargar</button></div>
				<div class="col-sm-2"><button class="btn secondary-btn" @click="seleccionar_mensaje">Ver mensaje</button></div>
			</div>
		</DataGrid>

		<Modal v-if="mensaje" :options="{width: (mensaje.garantia ? '90vw' : '50vw'), close: true}" @close="cerrar_mensaje">
			<div class="title">Mensaje</div>
			<div class="body">
				<div class="row">
					<div :class="(mensaje.garantia ? 'col-sm-6' : 'col-sm-12')">
						<fieldset>
							<legend>Mensaje</legend>
							<div class="row form-group">
								<label for="mensaje.nombre" class="col-form-label col-sm-2">Nombre</label>
								<div class="col-sm-10"><div class="form-control" >{{ mensaje.nombre }}</div></div>
							</div>
							<div class="row form-group">
								<label for="mensaje.email" class="col-form-label col-sm-2">Email</label>
								<div class="col-sm-4"><div class="form-control" >{{ mensaje.email }}</div></div>
								<label for="mensaje.telefono" class="col-form-label col-sm-2">Teléfono</label>
								<div class="col-sm-4"><div  class="form-control" >{{ mensaje.telefono }}</div></div>
							</div>
							<div class="row form-group">
								<label for="mensaje.mensaje" class="col-form-label col-sm-2">Mensaje</label>
								<div class="col-sm-10"><div class="form-control mensaje">{{ mensaje.mensaje }}</div></div>
							</div>
						</fieldset>
					</div>
					<div v-if="mensaje.garantia" class="col-sm-6">
						<fieldset>
							<legend>Garantia</legend>
							<div class="row form-group">
								<label for="garantia.fideicomiso" class="col-form-label col-sm-2">Fideicomiso</label>
								<div class="col-sm-4"><div class="form-control">{{ mensaje.garantia.fideicomiso }}</div></div>
								<label for="garntia.precio" class="col-form-label col-sm-2">Precio</label>
								<div class="col-sm-4"><div class="form-control">${{ $helper.moneyFormat(mensaje.garantia.precio) }}</div></div>
							</div>
							<div class="row form-group">
								<label for="garantia.titulo_nombre" class="col-form-label col-sm-2">Nombre</label>
								<div class="col-sm-4"><div class="form-control">{{ mensaje.garantia.titulo_nombre }}</div></div>
								<label for="garantia.anos" class="col-form-label col-sm-2">Edad</label>
								<div class="col-sm-4"><div class="form-control">{{ mensaje.garantia.edad }} años</div></div>
							</div>
							<div class="row form-group">
								<label for="garantia.descripcion" class="col-form-label col-sm-2">Descripción</label>
								<div class="col-sm-10"><div class="form-control">{{ mensaje.garantia.descripcion }}</div></div>
							</div>
							<div class="row form-group">
								<label for="garantia.recamaras" class="col-form-label col-sm-2">Recamaras</label>
								<div class="col-sm-4"><div class="form-control">{{ mensaje.garantia.recamaras }}</div></div>
								<label for="garantia.banos" class="col-form-label col-sm-2">Baños</label>
								<div class="col-sm-4"><div class="form-control">{{ mensaje.garantia.banos }}</div></div>
							</div>
							<div class="row form-group">
								<label for="garantia.medios_banos" class="col-form-label col-sm-2">Medios baños</label>
								<div class="col-sm-4"><div class="form-control">{{ mensaje.garantia.medios_banos }}</div></div>
								<label for="garantia.estacionamientos" class="col-form-label col-sm-2">Estacionamientos</label>
								<div class="col-sm-4"><div class="form-control">{{ mensaje.garantia.estacionamientos }}</div></div>
							</div>
							<div class="row form-group">
								<label class="col-form-label col-sm-2">Amenidades</label>
								<div class="col-sm-4"><span v-for="amenidad in mensaje.garantia.amenidades" class="badge badge-secondary text-white">{{ amenidad.amenidad }}</span></div>
							</div>
							<div class="row form-group">
								<label for="garantia.superficie" class="col-form-label col-sm-2">Superficie</label>
								<div class="col-sm-4"><div class="form-control">{{ mensaje.garantia.superficie }} m2</div></div>
								<label for="garntia.superficie_construida" class="col-form-label col-sm-2">Superficie construida</label>
								<div class="col-sm-4"><div class="form-control">{{ mensaje.garantia.superficie_construida }} m2</div></div>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'

	import api from '@/apps/garantias/api/otros'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'garantias_mensajes'
					,cols: {
						garantia: 'Fideicomiso'
						,nombre: 'Nombre'
						,email: 'Email'
						,telefono: 'Teléfono'
						,estatus: 'Estatus'
						,created_at: 'Fecha envio'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						garantia: function(val) {
							return val ? val.fideicomiso : '-';
						}
						,estatus: function(val) {
							return val == 1 ? 'Sin leer' : 'Leido';
						}
						,created_at: function(val, row, vue) {
							return vue.$moment(val).format('YYYY-MM-DD')
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccionados: []
				,mensajes: []
				,mensaje: null
			}
		}
		,mounted: function() {
			// obtener_mensajes_all
		}
		,methods: {
			dataGridActions: function(tipo, data) {
				if (tipo == 'options') {
					this.options = data;
					this.obtener_mensajes();
				}else {
					this.seleccionados = data;
					this.seleccionar = true;
				}
			}
			,obtener_mensajes: async function() {
				try {
					let res = (await api.obtener_mensajes_all(this.options)).data;

					this.mensajes = res.data;
					this.dataGridConfig.paginator.pagina_actual = res.current_page;
					this.dataGridConfig.paginator.total_registros = res.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,seleccionar_mensaje: async function() {
				try {
					if(this.seleccionados.length == 0)
						return this.$helper.showMessage('Error','Debes seleccionar un mensaje a mostrar','error','alert');

					this.$log.info('mensaje: ', this.seleccionados[0]);
					this.mensaje = (await api.obtener_mensaje(this.seleccionados[0].id)).data;

					this.ver_mensaje = true;

					if (this.mensaje.estatus)
						await api.leer_mensaje(this.mensaje.id);
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cerrar_mensaje: function() {
				this.mensaje = null;
				this.seleccionar = false;
				this.obtener_mensajes();
			}
		}
	}
</script>

<style lang="scss">
	.mensaje {
		height: 200px !important;
	}
</style>